$color-white: #fcfdff;
$color-red: #b01217;
$color-red-bright: #d7061d;
$color-blue-deep: #183f63;
$color-orange-bright: #fb6754;
$color-near-black: #222;

$color-main: $color-near-black;
$color-text: $color-white;
$color-body: $color-red;

$font-europa: 'europa'; // **
$font-sofia: 'sofia-pro'; // **
$font-body: 'skolar-sans-latin-extended';

$breakpoint-medium: 600px;

$max-width: 900px;
$spacing-std: 3vh;

$border: 3vw solid $color-main;
$border-lg: 1rem solid $color-main;
