@import 'variables';

html {
  font-size: 18px;

  @media screen and (min-width: $breakpoint-medium) {
    font-size: 20px;
  }
}

body {
  margin: 0;
}

body {
  color: $color-text;
  background-color: $color-body;
  font: {
    family: $font-body;
    weight: 200;
  }
}

a {
  color: inherit;
}

h1, h2, h3, strong {
  font-weight: 500;
}

p {
  margin-top: 0;
}

header {
  max-width: $max-width;
  border: $border;

  @media screen and (min-width: $breakpoint-medium) {
    width: 90vw;
    margin: $spacing-std $spacing-std 0;
    border: none;
  }
}

main {
  max-width: $max-width * 0.8;

  @media screen and (min-width: $breakpoint-medium) {
    width: 72vw;
    margin-left: 10vw;
    padding-left: $spacing-std;
    border-left: $border-lg;
  }
}

section {
  border-left: $border;
  border-right: $border;
  border-bottom: $border;

  padding: 0.5em;

  @media screen and (min-width: $breakpoint-medium) {
    border: none;
  }

  &:first-of-type {
    padding-top: 0;
  }

  h2 {
    border-bottom: $border;

    @media screen and (max-width: $breakpoint-medium) {
      margin-top: -0.35em;
      padding-bottom: 0.25em;
      margin-left: -$spacing-std;
      padding-left: $spacing-std;

      margin-right: -$spacing-std;
      padding-right: $spacing-std;
    }

    @media screen and (min-width: $breakpoint-medium) {
      border: none;
    }
  }
}

h1, h2, h3 {
  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 0 0.1em;
  }
}

h1 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 1.5em;
  padding-bottom: 0.1em;
  text-transform: lowercase;

  @media screen and (min-width: $breakpoint-medium) {
    margin: 0 0 1em;
    justify-content: flex-end;

    border-bottom: $border-lg;
  }
}

h3 {
  margin: 1em 0;
}

footer {
  display: none;
  justify-content: space-between;
}

.header-card p {
  padding-top: 1em;
}